<template>
    <CompanyBoxContent v-if="dph" :title="$t('company.dph') + ':'">
        <template #data>
            <div class="col-12">
                <div class="box d-flex justify-content-between align-items-center">
                    <span class="pe-2 dph_title">{{ $t('company.unreliablePayer') }}:</span>
                    <div v-if="dph.nespolehlivyPlatce" class="d-flex align-items-center true pe-2">
                        <span class="pe-1">{{ $t('utils.yes') }}</span>
                        <icon icon="True"></icon>
                    </div>
                    <div v-else class="d-flex align-items-center false pe-2">
                        <span class="pe-1">{{ $t('utils.no') }}</span>
                        <icon icon="False"></icon>
                    </div>
                </div>
                <div class="">
                    <div v-for="registryDph in dph.registraceDph" :key="registryDph.id" class="box mt-2">
                        <div class="row">
                            <div class="col-12 w-100">
                                <span>{{ $t('general.type') }}: {{ registryDph.typRegistrace }}</span>
                            </div>
                            <span class="pe-3">
                                {{ $t('general.from') }}:
                                {{ new Date(registryDph.registracePlatnaOd).toLocaleDateString() }}
                            </span>
                            <span v-if="registryDph.registracePlatnaDo" class="pe-3">
                                {{ $t('general.to') }}:
                                {{ new Date(registryDph.registracePlatnaDo).toLocaleDateString() }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </CompanyBoxContent>
</template>

<script>
import CompanyBoxContent from "@/components/CompanyBoxContent.vue"

export default {

    props: {
        company: {
            type: Object,
            required: true
        }
    },
    components: {
        CompanyBoxContent,
    },
    data() {
        return {

        }
    },
    computed: {
        dph() {
            return this.company.dph ? this.company.dph : null;
        },
    },
    methods: {

    },
}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";

.true {
    svg {
        stroke: $green;
        width: 45px;
        height: 45px;
    }
}

.false {
    svg {
        stroke: $red;
        width: 45px;
        height: 45px;
    }
}
</style>
<template>
  <div class="text-center">
    <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" class="btn btn_main">
      <icon icon="UserPlus" class="me-2" />
      <span>{{ $t('utils.addMember') }}</span>
    </button>
  </div>
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("institution.addingNewUser") }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div v-if="error" class="my-2">
            <span class="error">{{ error }}</span>
          </div>
          <div class="mb-3">
            <label for="email" class="form-label" style="font-weight: bold;">
              {{ $t("institution.users.email") }}:
            </label>
            <input v-model="formData.email" type="email" ref="email" class="form-control">
          </div>
          <div>
            <button @click="submitUser()" class="btn btn_main align-content-center">
              <span>{{ $t("utils.submit") }}</span>
            </button>
          </div>
        </div>
        <div v-if="success">
          <span class="success">{{ success }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formData: {
        email: ""
      },
      error: null,
      success: null
    }
  },
  methods: {
    submitUser: function () {
      this.error = false
      this.$store.getters.api.post('/institution/user/link', this.formData)
        .then(response => {
          const data = response.data;
          if (data.error_code == 104) {
            this.error = (this.$t("error.104"));
            this.$refs.email.setCustomValidity(this.$t("error.108"));
            this.$refs.email.reportValidity();
          }
          else if (data.error_code == 400) {
            this.error = (this.$t("error.400"));
          }
          else if (data.error_code == 302) {
            this.error = (this.$t("error.302"));
          }
          else {
            this.success = (this.$t("institution.addingSuccesfull"));
            this.formData.email = "";
          }
        })
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";

.modal-content {
  color: $black;
}

.success {
  color: $green;
  font-weight: bold;
  font-size: 20px;
}
</style>

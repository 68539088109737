import { createStore } from 'vuex'

import axios from "axios"
import { CONFIG } from "@/config.js"
import router from "@/router.js"
import { useRouter } from 'vue-router'

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}


const api = axios.create({
  baseURL: CONFIG.api.baseUrl,
  withCredentials: true,
  xsrfHeaderName: "X-CSRFTOKEN",
  xsrfCookieName: "csrftoken_new"
});


api.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response && [401, 403].some(code => code == error.response.status)) {
      router.push({ name: "login" })
    }

    return Promise.reject(error)
  }
)

// Create a new store instance.
const store = createStore({
  state() {
    return {
      api: api,
      user: {},
      permissions: [],
      selectedInstitution: null,
      shouldNavigateToNoInstitution: false,
      filters: {
        per_page: 10,
        page: 1,
        creation_date: null,
        expired: null,
        employees: [],
        trades: [],
        legalForms: [],
        tags: [],
        user_in_contact: null,
        address: {
          residence: null,
          branch: null,
          address: ''
        },
        regions: [],
        districts: []
      },
      version: "0.6.2"
    }
  },
  getters: {
    api: function (state) {
      return state.api;
    },
    isLoggedIn: function (state) {
      return Object.keys(state.user).length != 0;
    },
    isAdmin: function (state) {
      if (!state.user || !state.user.institutions) {
        return false;
      }

      for (var i = 0; i < state.user.institutions.length; i++) {
        if (state.user.institutions[i].id == state.selectedInstitution) {
          return state.user.institutions[i].admin;
        }
      }
      return false;
    },
    user: function (state) {
      return state.user;
    },
    language: function (state) {
      return state.language;
    },
    languages: function (state) {
      return state.languages;
    },
    institutions: function (state) {
      return state.user.institutions
    },
    selectedInstitution: function (state) {
      return Number(state.selectedInstitution);
    },
    version: function (state) {
      return state.version
    },
    filters: state => state.filters
  },
  mutations: {
    getUserProfile(state) {
      state.api.get("/auth/user").then(response => {
        const data = response.data.user;
        store.commit("saveUserProfile", data);
      });
    },
    saveUserProfile(state, data) {
      state.permissions = data.permissions;
      delete data.permissions;
      state.user = data;
      if (getCookie("institution_id") != "") {
        state.selectedInstitution = getCookie("institution_id")
      }
      else if (state.user.institutions.length != 0) {
        store.commit("setInstitution", state.user.institutions[0].id);
      }
    },
    setInstitution(state, institution) {
      if (state.selectedInstitution == institution) {
        return
      }

      state.selectedInstitution = institution;
      setCookie("institution_id", state.selectedInstitution, 1);
      router.go();
    },
    updateFilters(state, filters) {
      state.filters = filters;
    }
  },
  actions: {
    updateFilters({ commit }, filters) {
      commit('updateFilters', filters);
    }
  },
});


export default store;
<template>
    <div class="text-center mt-2">
        <h3 class="title">{{ $t("institution.institutionUsers") }}</h3>
    </div>
    <div class="table-responsive">
        <table class="table table-responsive table-hover w-100 mt-3">
            <thead class="table_head">
                <tr>
                    <th>{{ $t("institution.users.name") }}</th>
                    <th>{{ $t("institution.users.email") }}</th>
                    <th>{{ $t("institution.users.lastLogin") }}</th>
                    <th>{{ $t("institution.users.isAdmin") }}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(user, i) in users" :key="'users' + i" class="align-middle">
                    <td v-if="user.name && !isEditing(user.id)">
                        <span>{{ user.name }}</span>
                    </td>
                    <td v-else v-if="!isEditing(user.id)">
                        <span>{{ user.username }}</span>
                    </td>
                    <td v-if="isEditing(user.id)">
                        <input type="text" v-model="user.name" class="form-control">
                    </td>
                    <td>
                        <span>{{ user.email }}</span>
                    </td>
                    <td>
                        <i18n-d tag="span" :value="new Date(user.last_login)"
                            :format="{ dateStyle: 'long', timeStyle: 'medium' }">
                        </i18n-d>
                    </td>
                    <td v-if="!isEditing(user.id)">
                        <span v-if="user.admin">
                            <icon icon="True" class="icon_true" />
                        </span>
                        <span v-else>
                            <icon icon="False" class="icon_false" />
                        </span>
                    </td>
                    <td v-if="isEditing(user.id)">
                        <select v-model="user.admin" class="form-select">
                            <option :value="true">{{ $t("utils.yes") }}</option>
                            <option :value="false">{{ $t("utils.no") }}</option>
                        </select>
                    </td>
                    <td>
                        <div v-if="user.id !== $store.getters.user.id" class="d-flex">
                            <div v-if="!isEditing(user.id)" class="d-flex">
                                <button @click="deleteUser(user.id)" class="btn">
                                    <icon icon="Bin" class="icon_bin" />
                                </button>
                                <button @click="editUser(user.id)" class="btn">
                                    <icon icon="Edit" class="icon_edit" />
                                </button>
                            </div>
                            <div v-if="isEditing(user.id)" class="d-flex">
                                <button @click="saveUser(user)" class="btn btn_main">
                                    <span>{{ $t("utils.save") }}</span>
                                </button>
                                <button @click="cancelEditUser(user)" class="btn btn_main">
                                    <span>{{ $t("utils.cancel") }}</span>
                                </button>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="7" class="w-100" style="background-color: white;">
                        <!-- Modal for adding users to institution -->
                        <div class="add_user_modal py-2 text-center w-100">
                            <InstitutionAddUserModal />
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import InstitutionAddUserModal from '@/components/Institution/InstitutionAddUserModal.vue';

export default {
    components: {
        InstitutionAddUserModal,
    },
    props: {
        institutionId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            users: [],
            userEditingId: null,
            originalUserData: {},
        }
    },
    created() {
        this.loadData();
    },
    methods: {
        loadData: function () {
            this.$store.getters.api.get(`/institution/${this.institutionId}/settings/users`).then(function (response) {
                this.users = response.data.items;
            }.bind(this));
        },
        deleteUser: function (iuId) {
            this.$store.getters.api.delete(`/institution/user/delete/${iuId}`)
                .then(response => {
                    this.loadData()
                })
        },
        isEditing(iuId) {
            return this.userEditingId === iuId; // Check if the current user is being edited
        },
        cancelEditUser: function (iu) {
            this.userEditingId = null;
            this.loadData()
        },
        editUser: function (iuId) {
            if (this.userEditingId === iuId) {
                this.userEditingId = null;
            } else {
                this.userEditingId = iuId;
                // Store the original data for potential canceling
                this.originalUserData = { ...this.users.find(tag => tag.id === iuId) };
            }
        },
        saveUser: function (iu) {
            const updatedUserData = {
                id: iu.id,
                admin: iu.admin,
                name: iu.name
            };
            this.$store.getters.api.put(`/institution/${this.institutionId}/user/edit`, updatedUserData).then(response => {
                this.loadData();
                this.userEditingId = null;
            })
        }
    },
}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";

.title {
    font-size: 40px;
    color: $white;
    font-weight: 500;
    font-family: 'Rubik One Regular', sans-serif;
}

svg {
    width: 35px;
    height: 35px;
}

.icon_true {
    color: green;
}

.icon_false {
    color: $red;
}

.icon_bin,
.icon_edit {
    stroke: $purple;
    color: $purple;
    background-color: transparent;
}
</style>
<template>
    <div class="text-center mt-2">
        <h3 class="title">{{ $t('institution.tags.tags') }}</h3>
    </div>
    <div class="table-responsive">
        <table class="table table-responsive table-hover w-100 mt-3">
            <thead class="table_head">
                <tr>
                    <th class="col-3">{{ $t("institution.tags.name") }}</th>
                    <th class="col-3">{{ $t("institution.tags.color") }}</th>
                    <th class="col-3">{{ $t("institution.tags.bgColor") }}</th>
                    <th class="col-3"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(tag, i) in tags" :key="'tags' + i" class="align-middle">
                    <td v-if="!isEditing(tag.id)">
                        <span>{{ tag.name }}</span>
                    </td>
                    <td v-if="isEditing(tag.id)">
                        <input v-model="tag.name" class="form-control" />
                    </td>
                    <td v-if="!isEditing(tag.id)">
                        <span class="color-circle" :style="{ backgroundColor: tag.color }"></span>
                    </td>
                    <td v-if="isEditing(tag.id)">
                        <input v-model="tag.color" type="color" class="form-control color_input">
                    </td>
                    <td v-if="!isEditing(tag.id)">
                        <span class="color-circle" :style="{ backgroundColor: tag.bg_color }"></span>
                    </td>
                    <td v-if="isEditing(tag.id)">
                        <input v-model="tag.bg_color" type="color" class="form-control color_input">
                    </td>
                    <td class="d-flex align-items-center">
                        <div v-if="!isEditing(tag.id)" class="d-flex">
                            <button @click="deleteTag(tag.id)" class="btn">
                                <icon icon="Bin" class="icon_bin" />
                            </button>
                            <button @click="editTag(tag.id)" class="btn">
                                <icon icon="Edit" class="icon_edit" />
                            </button>
                        </div>
                        <div v-if="isEditing(tag.id)" class="d-flex align-items-center justify-content-end"
                            style="height: 49px;">
                            <button @click="saveTag(tag)" class="btn btn_main mx-2">
                                {{ $t("utils.save") }}
                            </button>
                            <button @click="cancelEdit(tag.id)" class="btn btn_secondary mx-2">
                                {{ $t("utils.cancel") }}
                            </button>
                        </div>
                    </td>
                </tr>
                <!-- Div for adding new tag -->
                <tr v-if="addingTag" class="align-middle">
                    <td colspan="7" style="background-color: white;">
                        <div class="mt-4">
                            <div class="row mb-2">
                                <div class="col-3" style="font-weight: bold;"></div>
                                <div class="col-3" style="font-weight: bold;"></div>
                                <div class="col-2" style="font-weight: bold;"></div>
                                <div class="col-4"></div>
                            </div>
                            <div class="row mb-2 align-items-center">
                                <div class="col-3">
                                    <input v-model="newTag.name" type="text" :placeholder="$t('institution.tags.name')"
                                        class="form-control tag-input">
                                </div>
                                <div class="col-3">
                                    <input v-model="newTag.textColor" type="color" class="form-control color_input">
                                </div>
                                <div class="col-2">
                                    <input v-model="newTag.backgroundColor" type="color"
                                        class="form-control color_input">
                                </div>
                                <div class="col-4 new_tag_buttons d-flex align-items-center">
                                    <button @click="addTag()" class="btn btn_main mx-2">
                                        {{ $t("utils.add") }}
                                    </button>
                                    <button @click="cancelAddingTag()" class="btn btn_main mx-2">
                                        {{ $t("utils.cancel") }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="7" class="w-100" style="background-color: white;">
                        <div class="text-center py-2">
                            <button @click="addingNewTag()" class="btn btn_main">
                                <icon icon="Tags" class="icon_tags me-2" />
                                <span>{{ addingTag ? $t("utils.cancel") : $t("utils.addTag") }}</span>
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        institutionId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            tags: [],
            editingTagId: null,
            addingTag: false,
            newTag: {
                name: "",
                textColor: "#e66465",
                backgroundColor: "#f6b73c"
            },
            originalTagData: {}, // Store the original tag data for reverting
        }
    },
    created: function () {
        this.loadData();
    },
    methods: {
        loadData: function () {
            this.$store.getters.api.get('/tags').then(function (response) {
                this.tags = response.data.items;
            }.bind(this));
        },
        addTag: function () {
            const formData = {
                name: this.newTag.name,
                textColor: this.newTag.textColor,
                backgroundColor: this.newTag.backgroundColor
            };
            this.$store.getters.api.post('/tags/add', formData).then(response => {
                // Refresh data after editing the tag
                this.loadData();
                // Reset editing state
                this.newTag.name = '';
                this.newTag.textColor = '';
                this.newTag.backgroundColor = '';
                this.addingTag = false
            })
                .catch(error => {
                    console.error('Error editing tag:', error);
                });
        },
        deleteTag: function (tagId) {
            this.$store.getters.api.delete(`/tags/delete/${tagId}`)
                .then(response => {
                    this.loadData();
                })
                .catch(error => {
                    console.error('Error deleting tag:', error);
                });
        },
        addingNewTag: function () {
            this.addingTag = !this.addingTag
        },
        cancelAddingTag: function () {
            this.addingTag = false
        },
        isEditing(tagId) {
            return this.editingTagId === tagId; // Check if the current tag is being edited
        },
        editTag(tagId) {
            if (this.editingTagId === tagId) {
                this.editingTagId = null;
            } else {
                this.editingTagId = tagId;
                // Store the original data for potential canceling
                this.originalTagData = { ...this.tags.find(tag => tag.id === tagId) };
            }
        },
        saveTag(tag) {
            const updatedTagData = {
                id: tag.id,
                name: tag.name,
                color: tag.color,
                bg_color: tag.bg_color
            };
            this.$store.getters.api.put(`/institution/${this.institutionId}/tags/edit`, updatedTagData).then(response => {
                this.loadData();
                this.editingTagId = null;
            })
        },
        cancelEdit(tagId) {
            // Revert the tag data to the original state
            const originalTag = this.originalTagData;
            const tagIndex = this.tags.findIndex(tag => tag.id === tagId);
            if (tagIndex !== -1) {
                this.tags[tagIndex].name = originalTag.name;
                this.tags[tagIndex].color = originalTag.color;
                this.tags[tagIndex].bg_color = originalTag.bg_color;
            }
            this.editingTagId = null;
        }
    },
}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";

.title {
    font-size: 40px;
    color: $white;
    font-weight: 500;
    font-family: 'Rubik One Regular', sans-serif;
}

.color_input {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 1px solid #000;
    padding: 0px;
}

.color_input:hover {
    cursor: pointer;
}

svg {
    width: 35px;
    height: 35px;
}

.icon_bin {
    color: $purple;
    background-color: transparent;
}

.icon_edit {
    stroke: $purple;
    color: $purple;
    background-color: transparent;
}

.color-circle {
    display: inline-block;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 1px solid #000;
}
</style>
<template>
    <div :style="paginatorStyle" class="d-flex justify-content-center align-items-center gap-1 paginator">
        <div @click="setPage(1)" class="btn button my-0 px-3" v-if="page > 1">
            <span class="switch pt-1">&lt;&lt;</span>
        </div>
        <div @click="setPage(page - 1)" class="btn button px-3 d-flex align-items-center justify-content-center my-0"
            v-if="page > 1">
            <span class="switch pt-1">&lt;</span>
        </div>
        <div v-for="(p, index) in computedPages" :key="p" @click="setPage(p)"
            class="btn button d-flex align-items-center justify-content-center my-0 px-3"
            :class="{ 'btn-active': p === page }">
            <span class="number pt-1" :class="{ 'btn-active': p === page }">{{ p }}</span>
        </div>
        <div @click="setPage(page + 1)" class="btn button d-flex align-items-center justify-content-center my-0 px-3"
            v-if="page < lastPage">
            <span class="switch pt-1">&gt;</span>
        </div>
        <div @click="setPage(lastPage)" class="btn button d-flex align-items-center justify-content-center my-0 px-3"
            v-if="page < lastPage">
            <span class="switch pt-1">&gt;&gt;</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        page: { type: Number, default: 1 },
        pages: { type: Number },
    },
    data() {
        return {};
    },
    computed: {
        totalPages() {
            if (this.pages && this.pages >= 1) {
                return this.pages
            }
            return 1;
        },
        computedPages() {
            var list = [];
            if (this.totalPages <= 5) {
                for (var i = 1; i <= this.totalPages; i++) {
                    list.push(i);
                }
                return list;
            }

            var min = Math.max(this.page - 2, 1);
            for (var i = min; i < this.page; i++) {
                list.push(i);
            }
            list.push(this.page);
            var max = Math.min(this.lastPage, this.page + 2);
            for (var i = this.page + 1; i <= max; i++) {
                list.push(i);
            }

            return list;
        },
        lastPage() {
            return this.totalPages;
        },
        paginatorStyle() {
            const buttonMargin = 8; // Margin between buttons
            const baseButtonWidth = 50; // Base width of each button for small numbers

            // Calculate the width of each button dynamically based on the length of the number
            const buttonWidths = this.computedPages.map(p => Math.max(baseButtonWidth, p.toString().length * 10 + 20));

            // Total width calculation including margins and navigation buttons
            const paginatorWidth = buttonWidths.reduce((acc, width) => acc + width, 0) +
                buttonMargin * (buttonWidths.length - 1) +
                (this.page > 1 ? 2 : 0) * (baseButtonWidth + buttonMargin) + // << and <
                (this.page < this.lastPage ? 2 : 0) * (baseButtonWidth + buttonMargin); // > and >>

            return {
                width: paginatorWidth + 'px',
            };
        }
    },
    methods: {
        setPage(page) {
            this.$emit("update:page", page);
        }
    }
};
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";

.paginator {
    background: linear-gradient(90deg, $purple 26%, $light-purple 100%);
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
    border-radius: 50px;
    transition: width 0.3s ease; // Add transition for smooth resizing
    font-size: 18px;
    font-family: 'Rubik One Regular', sans-serif;
    height: 44px;
}

.button {
    background-color: transparent;
    border: none;
    border-radius: 20px;
    color: $white;
    font-weight: 400;
    font-size: 18px;
}

.button:hover {
    color: $black;
    background-color: $white;
}

.button:hover .number {
    color: $black;
}

.button:hover .button {
    color: $black;
}

.btn-active {
    background-color: $white;
    color: $black;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 400;
}
</style>

<template>
    <CompanyBoxContent v-if="shouldRenderCompanyBoxContent">
        <template #data>
            <div v-if="companyAddress && companyAddress.length > 0" class="title">
                <h3>{{ $t('company.mapOfEstablishments') }}:</h3>
            </div>
            <div v-if="companyAddress && companyAddress.length > 0"
                class="box d-flex align-items-center justify-content-center mb-4">
                <div class="companyAddress" id="map2">
                    <CompanyEstablishmentsMap :companyAddress="companyAddress" />
                </div>
            </div>
            <div>
                <div class="title">
                    <h3>{{ $t('company.establishments') }}:</h3>
                </div>
                <div class="box p-0">
                    <div v-for="(establishment, index) in visibleEstablishments" :key="index"
                        :class="['p-3 inner', index % 2 === 0 ? 'bg' : '']">
                        <span v-if="!establishment.platnostDo">{{ establishment.sidloProvozovny.textovaAdresa }}</span>
                    </div>
                </div>
            </div>
        </template>

        <template #toggle-button>
            <!-- Button to toggle visibility -->
            <div class="text-center toggle mt-3">
                <button class="btn btn_main" type="button" data-bs-toggle="modal" data-bs-target="#establishmentsModal">
                    <icon icon="ChevronDownWhite"></icon>
                    {{ $t('general.showMore') }}
                </button>
            </div>
        </template>
    </CompanyBoxContent>

    <div class="modal fade" id="establishmentsModal" tabindex="-1" aria-labelledby="establishmentsModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="establishmentsModalLabel">{{ $t('company.establishments') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body p-0">
                    <div v-for="(establishment, index) in hiddenEstablishments" :key="index + visibleCount"
                        :class="['p-3', (index + visibleCount) % 2 === 0 ? 'bg' : '']">
                        <span>{{ establishment.sidloProvozovny.textovaAdresa }}</span>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn_secondary" data-bs-dismiss="modal">
                        {{ $t('utils.close') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CompanyBoxContent from "@/components/CompanyBoxContent.vue"
import CompanyEstablishmentsMap from "@/components/CompanyDetail/CompanyEstablishmentsMap.vue";

export default {
    props: {
        company: {
            type: Object,
            required: true
        },
        companyAddress: {
            type: Array,
            required: true
        }
    },
    components: {
        CompanyBoxContent,
        CompanyEstablishmentsMap
    },
    data() {
        return {
            visibleCount: 6,
        }
    },
    computed: {
        shouldRenderCompanyBoxContent() {
            if (this.company?.rzp?.zivnosti) {
                return this.company?.rzp?.zivnosti?.some(activity => activity.provozovny?.length > 0) ?? false;
            }
        },
        visibleEstablishments() {
            if (this.company && this.company.unique_establishments) {
                return this.company.unique_establishments.slice(0, this.visibleCount);
            }
        },
        hiddenEstablishments() {
            if (this.company && this.company.unique_establishments) {
                return this.company.unique_establishments.slice(this.visibleCount);
            }
        },
    },
}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";

.title {
    padding: 0 0 5px 20px;

    h3 {
        font-weight: 700;
        font-size: 24px;
    }
}

#map2 {
    height: 180px;
    width: 100%;
    border-radius: 35px;
}

.inner {
    border-radius: 35px;

    p {
        padding: 5px 20px;
    }
}

.bg {
    background-color: $grey;
}

.toggle {
    svg {
        stroke: $white;

    }
}
</style>
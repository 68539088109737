<template>
    <!-- Register person without adding to institution -->
    <div class="">
        <div class="row justify-content-center align-items-center vh-100">
            <div class="col-12 col-md-10 col-lg-8 col-xxl-6">
                <div class="w-100 inner">
                    <div>
                        <h1 class="text-center company_name">{{ $t("titles.companyName") }}</h1>
                    </div>
                    <div class="d-flex flex-wrap justify-content-between mt-4">
                        <h2 class="login_title">{{ $t("register.registerPerson") }}</h2>
                    </div>
                    <div v-if="invalid" class="alert alert-danger mt-3 mb-1" role="alert">
                        {{ invalid }}
                    </div>
                    <div class="d-flex name_input_group mt-4 justify-content-between">
                        <div class="col input_group me-5">
                            <label for="firstName" class="py-3 ms-4">
                                {{ $t("register.firstName") }}:
                            </label>
                            <input type="text" class="px-0" ref="firstName" v-model="formData.firstName">
                        </div>
                        <div class="col-auto"></div>
                        <div class="col input_group">
                            <label for="lastName" class="py-3 ms-4">
                                {{ $t("register.lastName") }}:
                            </label>
                            <input type="text" class="px-0" ref="lastName" v-model="formData.lastName">
                        </div>
                    </div>
                    <div class="d-flex input_group mt-4">
                        <label for="email" class="py-3 mx-4">
                            {{ $t("register.email") }}:
                        </label>
                        <input type="email" class="" ref="email" v-model="formData.email">
                    </div>
                    <div class="d-flex input_group mt-4">
                        <label for="phone" class="py-3 mx-4">
                            {{ $t("register.phone") }} ({{ $t("general.optional") }}):
                        </label>
                        <input type="number" class="" ref="phone" v-model="formData.phone">
                    </div>
                    <div class="d-flex input_group mt-4">
                        <label for="password" class="py-3 mx-4">
                            {{ $t("register.password") }}:
                        </label>
                        <input type="password" class="" ref="password" v-model="formData.password">
                    </div>
                    <div class="d-flex input_group mt-3">
                        <label for="confirmPassword" class="py-3 mx-4">{{ $t("register.confirmPassword") }}:</label>
                        <input type="password" class="" ref="confirmPassword" v-model="formData.passwordConfirm">
                    </div>
                    <div class="checkbox_group mt-3">
                        <div class="form-check">
                            <input type="checkbox" class="me-2 form-check-input checkbox" ref="checkbox"
                                v-model="formData.PersonalDataCheck">
                            <label for="personalDataCheck" class="form-check-label pt-1">
                                {{ $t("register.protectionOfPersonalData") }}
                            </label>
                        </div>
                        <div class="form-check mt-1">
                            <input type="checkbox" class="me-2 form-check-input checkbox" ref="checkbox"
                                v-model="formData.termsAndConditions">
                            <label class="form-check-label pt-1">
                                {{ $t("register.termsAndConditions") }}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="text-center">
                    <button type="submit" class="btn btn_submit" @click="register()">
                        <span>{{ $t("register.button") }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            formData: {
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                password: "",
                passwordConfirm: "",
                PersonalDataCheck: false,
                termsAndConditions: false,
            },
            token: this.$route.params.token,
            invalid: false
        }
    },
    methods: {
        register: function () {
            this.$store.getters.api.post("/auth/register", this.formData)
                .then(
                    (response) => {
                        const data = response.data;
                        if (data.error_code == 102) {
                            this.invalid = (this.$t("error.102"));
                            this.$refs.password.setCustomValidity(this.$t("error.102"));
                            this.$refs.password.reportValidity();
                        }
                        else if (data.error_code == 104) {
                            this.invalid = (this.$t("error.104"));
                            this.$refs.email.setCustomValidity(this.$t("error.104"));
                            this.$refs.email.reportValidity();
                        }
                        else if (data.error_code == 105) {
                            this.invalid = (this.$t("error.105"));
                        }
                        else if (data.error_code == 106) {
                            this.invalid = (this.$t("error.106"));
                        }
                        else if (data.error_code == 113) {
                            this.invalid = (this.$t("error.113"));
                        }
                        else if (data.error_code == 114) {
                            this.invalid = (this.$t("error.114"));
                        }
                        else if (data.error_code == 120) {
                            this.invalid = true;
                        }
                        else if (data.error_code == 300) {
                            this.invalid = (this.$t("error.300"));
                        }
                        else {
                            // Emitting to InstitutionAccesRegister component for adding user to institution
                            const userId = data.user_id;
                            const token = this.$route.params.token;
                            this.$emit('user-added', { userId, token });
                            this.$router.push({ name: "companies" });
                        }
                    }
                )
        },
    },
}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";

.inner {
    background: radial-gradient(circle, $light-purple 6%, $purple 100%);
    border-radius: 50px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    padding: 45px 50px 50px 50px;
    margin: 0 0 20px 0;
    overflow-y: hidden;
}

.company_name {
    font-family: "Outfit";
    font-weight: 800;
    font-size: 66px;
    text-shadow: 5px 5px 10px $black;
    color: $white;
}

.login_title {
    color: $white;
    font-size: 30px;
}

.input_group {
    background-color: $white;
    border-radius: 50px;

    label {
        color: $black;
        width: 40%;
        font-size: 20px;
    }

    input {
        background-color: $white;
        border: none;
        width: 65%;
        outline: none;
        border-radius: 0 50px 50px 0;
        padding: 0 20px;
        font-size: 20px;
    }
}

.name_input_group {
    label {
        width: 40%;
    }

    input {
        width: 50%;
    }
}

.form-check-input[type=checkbox] {
    border-radius: 50%;
    border: none;
    width: 1.2em;
    height: 1.2em;
}
</style>
<template>
    <CompanyBoxContent :title="$t('company.yourDocuments') + ':'">
        <template #data>
            <div v-if="documents && institution">
                <div class="table-responsive">
                    <table class="table table-hover">
                        <thead class="table_head">
                            <tr>
                                <th>{{ $t("company.name") }}</th>
                                <th>{{ $t("company.size") }}</th>
                                <th>{{ $t("general.actions") }}</th>
                            </tr>
                        </thead>
                        <tbody class="align-middle">
                            <tr v-if="documents && documents.length > 0" v-for="document in documents"
                                :key="document.id" class="align-middle">
                                <td>
                                    <span>{{ document.name }}</span>
                                </td>
                                <td>
                                    <span>{{ document.file_size }}</span>
                                </td>
                                <td class="d-flex">
                                    <button @click="deleteDocument(document.id)" class="btn">
                                        <icon icon="Bin" class="icon_bin" />
                                    </button>
                                    <button @click="downloadDocument(document.id)" class="btn">
                                        <icon icon="Download" class="download_icon" />
                                    </button>
                                    <button @click="editDocument(document.id)" data-bs-toggle="modal"
                                        data-bs-target="#exampleModal" type="button" class="btn">
                                        <icon icon="Edit" class="edit_icon" />
                                    </button>
                                </td>
                            </tr>
                            <tr v-else>
                                <td colspan="3">
                                    <div class="no_documents">
                                        <span>{{ $t("company.noDocuments") }}</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-if="institution.plan.max_file_size" class="text-center mt-3" data-bs-toggle="modal"
                    data-bs-target="#exampleModal">
                    <button class="btn btn_main" type="button" @click="(uploadingFile = true, editingName = false)">
                        <icon icon="Upload" class="me-2" />
                        <span class="align-middle">{{ $t('general.uploadDocuments') }}</span>
                    </button>
                </div>
                <button v-else disabled class="btn btn_main mt-3 btn_disabled disabled " type="button">
                    <span>{{ $t('general.notPaidDocuments') }}</span>
                </button>
            </div>

        </template>
    </CompanyBoxContent>
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title ms-auto">{{ $t("general.uploadDocuments") }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-if="error" class="my-2">
                        <span class="error">{{ error }}</span>
                    </div>
                    <div v-if="uploadingFile" class="mb-3">
                        <input type="file" class="form-control" @change="onFileChanged" />
                    </div>
                    <div v-if="editingName" class="mb-3">
                        <label class="form-label">{{ $t("company.name") }}</label>
                        <input type="text" class="form-control" v-model="editData.fileName" />
                    </div>
                    <div class="text-center">
                        <button v-if="uploadingFile" @click="submitDocument()"
                            class="btn btn_main align-content-center">
                            <span>{{ $t("utils.submit") }}</span>
                        </button>
                        <button v-if="editingName" @click="editDocumentSubmit()"
                            class="btn btn_main align-content-center">
                            <span>{{ $t("utils.submit") }}</span>
                        </button>
                    </div>
                </div>
                <div v-if="success" class="text-center mb-2">
                    <span class="success">{{ success }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CompanyBoxContent from "@/components/CompanyBoxContent.vue"
import { CONFIG } from '@/config.js';

export default {
    props: {
        company: {
            type: Object,
            required: true
        }
    },
    components: {
        CompanyBoxContent,
    },
    data() {
        return {
            documents: [],
            file: null,
            uploadingFile: false,
            editingName: false,
            editData: {
                id: null,
                fileName: null,
            },
            error: null,
            success: null,
            downloadedFile: null,
            institution: null
        }
    },
    created: function () {
        this.loadData();
    },
    methods: {
        loadData: function () {
            this.$store.getters.api.get(`/company/${this.$route.params.slug}/documents`).then(function (response) {
                this.documents = response.data.items;
            }.bind(this));
            if (this.$store.getters.selectedInstitution) {
                const institutionId = this.$store.getters.selectedInstitution;
                this.$store.getters.api.get(`/institution/${institutionId}`).then(function (response) {
                    this.institution = response.data.institution;
                }.bind(this));
            }
        },
        onFileChanged: function (event) {
            this.file = event.target.files[0];
            const maxSizeBytes = CONFIG.MAX_FILE_SIZE_MB;

            const file = event.target.files[0];

            if (file && file.size > maxSizeBytes) {
                this.error = this.$t("error.fileTooLarge");
                this.file = null;
            } else {
                this.file = file;
                this.error = null;
            }
        },
        submitDocument: function () {
            if (!this.file) {
                this.error = this.$t("error.noFileSelected");
                return;
            }

            let formData = new FormData();
            formData.append('file', this.file);

            this.$store.getters.api.post(`/company/${this.$route.params.slug}/document/add`, formData, {
            }).then(function (response) {
                this.success = this.$t('general.documentUploaded');
                this.uploadingFile = false;
                this.loadData();
            }.bind(this));
        },
        deleteDocument: function (id) {
            this.$store.getters.api.delete(`/company/${this.$route.params.slug}/document/${id}/delete`).then(function (response) {
                this.loadData();
            }.bind(this));
        },
        editDocument: function (id) {
            this.uploadingFile = false;
            this.editingName = true;
            this.editData.id = id;
        },
        editDocumentSubmit: function () {
            if (!this.editData.fileName) {
                this.error = this.$t("error.noName");
            };
            let id = this.editData.id;
            this.$store.getters.api.put(`/company/${this.$route.params.slug}/document/${id}/edit`, { name: this.editData.fileName }).then(function (response) {
                this.success = this.$t('general.nameChanged');
                this.editingName = false;
                this.loadData();
            }.bind(this));
        },
        downloadDocument(documentId) {
            this.$store.getters.api.get(`/company/${this.$route.params.slug}/document/${documentId}/download`, {
                responseType: 'blob'
            }).then((response) => {
                if (response.data) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;

                    // Extract the filename from the Content-Disposition header
                    const contentDisposition = response.headers['content-disposition'];
                    let filename = 'downloaded_file.pdf'; // Default filename
                    console.log(contentDisposition);

                    if (contentDisposition) {
                        // Match the filename in the Content-Disposition header
                        console.log(contentDisposition);
                        const matches = contentDisposition.match(/filename="?([^";]+)"?/);
                        if (matches.length > 1) {
                            filename = matches[1];
                        }
                    }
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    URL.revokeObjectURL(url); // Clean up the URL object
                } else {
                    console.error("No data in response");
                }
            }).catch((error) => {
                console.error("Download error:", error);
            });
        }

    }
}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";

.success {
    color: $green;
}

.btn_disabled {
    background-color: $beige !important;
    border: none;

    span {
        color: $black !important;
    }
}

.no_documents {
    text-align: start;
    width: 100%;
    border-radius: 35px;
    font-size: 20px;
}

.icon_bin,
.download_icon,
.edit_icon {
    color: $purple;
    stroke: $purple;
    width: 30px;
    height: 30px;

}
</style>
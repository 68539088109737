<template>
    <CompanyBoxContent v-if="dph" :title="`${$t('company.currentAccounts')} (${$t('company.byDphRegister')}):`">
        <template #data>
            <div class="box">
                <div v-if="dph.bankovniUcty && dph.bankovniUcty.length" v-for="currentAccounts in dph.bankovniUcty"
                    :key="currentAccounts.id" class="row">
                    <span v-if="currentAccounts.cisloUctu" class="col-12 col-xxl-6" style="word-break: break-word;">
                        {{ currentAccounts.cisloUctu }}
                    </span>
                    <span v-if="currentAccounts.banka" class="col-12 col-xxl-6">({{ currentAccounts.banka }})</span>
                    <div class="col-12">
                        <span v-if="currentAccounts.datumUctu" class="pe-3">od: </span>
                        <span v-if="isValidDate(currentAccounts.datumUctu)">
                            {{ formatDate(currentAccounts.datumUctu) }}
                        </span>
                        <span v-else>
                            {{ currentAccounts.datumUctu }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="mt-4">
                <div class="title">
                    <h3>{{ $t('company.historyAccounts') }}:</h3>
                </div>
                <div class="box">
                    <div v-if="dph.bankovniUcty && dph.bankovniUcty.length"
                        v-for="oldAccounts in dph.historieBankovnichUctu" :key="oldAccounts.id" class="row">
                        <span v-if="oldAccounts.cisloUctu" class="col-12 col-xxl-6">{{ oldAccounts.cisloUctu }}</span>
                        <span v-if="oldAccounts.banka" class="col-12 col-xxl-6">({{ oldAccounts.banka }})</span>
                        <div class="row">
                            <span v-if="oldAccounts.datumZverejneni" class="pe-3 col-12 col-xxl-6">{{ $t("general.from")
                                }}:
                                {{ new Date(oldAccounts.datumZverejneni).toLocaleDateString() }}
                            </span>
                            <span v-if="oldAccounts.datumUkonceniZverejneni" class="pe-3 col-12 col-xxl-6">{{
        $t("general.to") }}:
                                {{ new Date(oldAccounts.datumUkonceniZverejneni).toLocaleDateString() }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </CompanyBoxContent>


</template>

<script>
import CompanyBoxContent from "@/components/CompanyBoxContent.vue"

export default {
    props: {
        company: {
            type: Object,
            required: true
        }
    },
    components: {
        CompanyBoxContent,
    },
    data() {
        return {

        }
    },
    computed: {
        dph() {
            return this.company.dph ? this.company.dph : null;
        },
    },
    methods: {
        isValidDate(dateString) {
            // Check if the date string can be converted to a valid Date object and not shows Invalid date
            const date = new Date(dateString);
            return !isNaN(date.getTime());
        },
        formatDate(dateString) {
            // Format the date if valid
            const date = new Date(dateString);
            return date.toLocaleDateString();
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";

.title {
    padding: 0 0 5px 20px;

    h3 {
        font-weight: 700;
        font-size: 24px;
    }
}
</style>
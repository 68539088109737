<template>
    <div class="language_selector d-flex">
        <div>
            <icon icon="Language" class="me-0 icon"></icon>
        </div>
        <select v-if="langs.length > 1" v-model="language" class="form-select w-100">
            <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
                {{ $t("langs." + lang) }}
            </option>
        </select>
    </div>
</template>

<script>
import Icon from '@/components/Icon.vue';
export default {
    components: {
        Icon
    },
    data() {
        return {
            language: null
        }
    },
    mounted: function () {
        this.language = this.$i18n.locale;
    },
    computed: {
        langs: function () {
            return Object.keys(this.$i18n.messages);
        }
    },
    watch: {
        language: function (v) {
            if (v !== null && v !== undefined) {
                this.$i18n.locale = v;
                localStorage.setItem('lang', v);
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";

.language_selector {
    display: flex;
    align-items: center;
    background-color: $dark-purple;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    border-radius: 25px;
    padding: 7px 0px 4px 20px;
    height: 45px;
}

.language_selector .form-select {
    flex-grow: 1;
    border: none;
    /* Remove the default border on focus */
    outline: none;
}

select {
    border: none;
    background-color: transparent;
    color: $white;
    outline: none;
    // Hide the arrow
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: none;
    text-shadow: 0 0 0 #000;
    box-shadow: none;
    outline: none;
    &:focus{
        box-shadow: none;
        outline: none;
    }
    option {
        background-color: $dark-purple;
    }
}
</style>